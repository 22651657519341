import { defineStore } from "pinia";
import { jwtDecode } from "jwt-decode";
import axios from "axios";

import { dafisApi } from "@/helpers/api/dafisApi";
import { SessionHelper } from "@/helpers/sessionHelper";
import { EventHelper } from "@/helpers/eventHelper";
import { User, USER_ROLE } from "@/models/user";

let oldSearchTerm = null;

export const useMeStore = function () {
  const store = defineStore("meStore", {
    state: () => ({
      loggedInUser: null,
      isRestorePasswordRequestedInApi: false,
      userSettingsMap: null
    }),

    actions: {
      async loadUserSettings() {
        if (!this.userSettingsMap) {
          return await this.reloadUserSettings();
        }
      },

      async reloadUserSettings() {
        const response = await dafisApi().get("/UserSettingFunction");
        if (!this.userSettingsMap) {
          this.userSettingsMap = {};
        }
        response.data.forEach(setting => {
          this.userSettingsMap[setting.key] =
            setting.value === "true" ? true : setting.value === "false" ? false : setting.value;
        });
      },

      async saveUserSetting(key, value) {
        const valueToSave = Object.assign({}, value);

        if (valueToSave.searchTerm !== oldSearchTerm) {
          oldSearchTerm = valueToSave.searchTerm;
          return;
        }

        delete valueToSave.searchTerm;
        const response = await dafisApi().post("/UserSettingFunction", { key, value: JSON.stringify(valueToSave) });
        if (response.status === 200) {
          this.userSettingsMap[key] = value;
          return true;
        }
        return false;
      },

      async requestRestorePassword(email) {
        await axios.post(
          `${import.meta.env.VITE_DAFIS_API_URL}/Authentication/requestRestorePassword?code=${
            import.meta.env.VITE_APIKEY_AUTHENTICATION
          }`,
          { email }
        );
        this.isRestorePasswordRequestedInApi = true;
        return true;
      },

      async getRestorePasswordRequest(restorePasswordRequestId) {
        try {
          const response = await axios.get(
            `${import.meta.env.VITE_DAFIS_API_URL}/Authentication/restorePassword?code=${
              import.meta.env.VITE_APIKEY_AUTHENTICATION
            }&restoreRequestId=${restorePasswordRequestId}`
          );

          if (response.status === 200 && response.data) {
            return response.data;
          }
          return null;
        } catch {
          return null;
        }
      },

      async doRestorePassword(restorePasswordRequestId, userId, newPassword, invitation = false) {
        try {
          const encodedPassword = btoa(("0uhjnmnT/RTGkl" + newPassword + "poijh&TGFVfdvbnkLK").split("").reverse().join(""));

          const response = await axios.post(
            `${import.meta.env.VITE_DAFIS_API_URL}/Authentication/restorePassword?code=${
              import.meta.env.VITE_APIKEY_AUTHENTICATION
            }&restoreRequestId=${restorePasswordRequestId}`,
            {
              restoreRequestId: restorePasswordRequestId,
              userId,
              newPassword: encodedPassword,
              invitation
            }
          );

          if (response.status === 204) {
            return true;
          }
          return false;
        } catch {
          return false;
        }
      },

      reloadMinimumData() {
        this.reloadUserSettings();
      },

      confirmSignUp(confirmToken) {
        return axios.post(
          `${import.meta.env.VITE_DAFIS_API_URL}/Authentication/confirmSignUp?code=${
            import.meta.env.VITE_APIKEY_AUTHENTICATION
          }`,
          { confirmToken }
        );
      }
    },

    getters: {
      user(state) {
        return state.loggedInUser;
      },

      isRestorePasswordRequested(state) {
        return state.isRestorePasswordRequestedInApi;
      },

      isLoggedInUserOwner(state) {
        return state.loggedInUser?.role === USER_ROLE.OWNER || false;
      },

      isLoggedInUserSuperuser(state) {
        return state.loggedInUser?.role === USER_ROLE.SUPERUSER || false;
      },

      setting(state) {
        return (key, notLoadedValue) => {
          if (!state.userSettingsMap) {
            return notLoadedValue;
          }
          return state.userSettingsMap[key] !== undefined ? state.userSettingsMap[key] : null;
        };
      },

      // Permission matrix

      canEditRegistration(state) {
        return registration => {
          if (state.loggedInUser?.role === USER_ROLE.OWNER || state.loggedInUser?.role === USER_ROLE.SUPERUSER) {
            return true;
          }

          if (state.loggedInUser?.role === USER_ROLE.USER) {
            if (registration.userId === state.loggedInUser.id) {
              return true;
            }
          }
          return false;
        };
      }
    }
  });

  const meStore = store(...arguments);

  if (!meStore._initialized) {
    if (SessionHelper.amIAuthenticated) {
      parseUserFromSessionHelper(meStore);
      //meStore.loadUserSettings();
    }

    EventHelper.on("userSessionChanged", amIAuthenticated => {
      if (amIAuthenticated) {
        parseUserFromSessionHelper(meStore);
        //meStore.loadUserSettings();
      } else {
        meStore.loggedInUser = null;
      }
    });
    meStore._initialized = true;
  }
  return meStore;
};

function parseUserFromSessionHelper(meStore) {
  const accessToken = SessionHelper.accessToken;

  if (accessToken) {
    const parsedToken = jwtDecode(accessToken);

    const user = new User();

    user.email = parsedToken.sub;
    user.company = parsedToken.company;
    user.id = parsedToken.subId;
    user.role = parsedToken.role;
    user.subscription = parsedToken.subs;
    user.organizationId = parsedToken.aud;

    meStore.loggedInUser = user;
  } else {
    meStore.loggedInUser = null;
  }
}
